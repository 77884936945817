$(function() {
    let urlhashPart = window.location.hash;
    if (urlhashPart == "#notify") {
        $("#notifications-dropdown").hide();
    }

    //dropdown code
    let notificationDropDown = $(
        "#notifications-dropdown .ui-dropdown-menu-wrap"
    );
    let allNotifications = $("[id^='notification-message-']"),
        totalNotificationsCountElement = $("#total_notifications_count"),
        totalNotificationsCount = totalNotificationsCountElement.data(
            "total-notifications"
        ),
        csrfToken = $("meta[name='csrf-token']").attr("content");
    $(document).on("click", '#notifications-dropdown .ui-notifications-link', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var notify_link = $(this);

        let notification_id = $(this).data("id");
        let statusUrl = $(this).data("status-update-url");

        if (notification_id === -1) {
            window.location.href = "/profile";
            return;
        }


            $.ajax({
                url: statusUrl,
                type: "POST",
                data: { notification_id, isRead: 1 },
                dataType: "json",
                headers: { "X-CSRF-TOKEN": csrfToken },
                statusCode: {
                    411: function () {
                        $('div.modal').removeClass('show');
                        window.location.href = '/profile';
                    }
                },
                success: function (resp) {
                    notificationStatusChangeSuccessHandler(resp)
                },
                error: function (resp) {
                    notificationStatusChangeErrorHandler(resp)
                }
            });


        let notificationStatusChangeSuccessHandler = function(result) {
            let subject_id = notify_link.data("subject-id"),
                notify_type = notify_link.data("notify-type"),
                reset_nofity_count = notify_link.hasClass('ui-unread') ? true : false;

            if (totalNotificationsCount > 0 && reset_nofity_count) {
                totalNotificationsCount -= 1;
                totalNotificationsCountElement.text(totalNotificationsCount);
                // $(this).hide();
            }
            if (totalNotificationsCount == 0) {
                totalNotificationsCountElement.hide();
                // $(this).hide();
                // notificationDropDown.hide();
            }
            $(this).removeClass('ui-unread');
            if(result.data.notificationIcons) {
                $(this).find('img.ui-notification-icon').attr('src', result.data.notificationIcons);
            }
            if (
                $.inArray(notify_type, [
                    "SPEAKING_TEST_RESULT",
                    "WRITING_TEST_RESULT"
                ]) !== -1
            ) {
                window.location.href =
                    $("#url").val() +
                    "/notifications/view-test-result/" +
                    subject_id;
            } else if (notify_type == "COURSE_PURCHASE") {
                window.location.href =
                    $("#url").val() + "/course/" + result.data.slug + "/view";
            } else if (notify_type == "PROFILE_UPDATE_BASIC") {
                window.location.href = $("#url").val() + "/profile";
            } else if (
                $.inArray(notify_type, [
                    "PROFILE_UPDATE_USERNAME",
                    "PROFILE_UPDATE_PASSWORD"
                ]) !== -1
            ) {
                window.location.href = $("#url").val() + "/profile#security";
            }
        };
        let notificationStatusChangeErrorHandler = function(
            xhrObject,
            status,
            error
        ) {
            //failure
        };
    });

    //profile notifications section
    let notificationListsElementList = $("[id^='list-notification-message-']");
    attachNotificationUpdateStatusChangeHandler(notificationListsElementList);

    function attachNotificationUpdateStatusChangeHandler(
        notificationListsElementList
    ) {
        notificationListsElementList.each(function(index, element) {
            let notificationStatusUpdateUrl = $(this).data(
                "notification-update-url"
            );
            let redirectUrl = $(this).data("redirect-url");
            let notification_id = $(this).data("notify-id");
            let isRead = 1;

            $(this).on("click", function(e) {
                e.preventDefault();
                e.stopPropagation();
                $.ajax({
                    url: notificationStatusUpdateUrl,
                    type: "POST",
                    data: { notification_id, isRead },
                    dataType: "json",
                    headers: { "X-CSRF-TOKEN": csrfToken },
                    statusCode: {
                        411: function () {
                            $('div.modal').removeClass('show');
                            window.location.href = '/profile';
                        }
                    },
                    success: function(result) {
                        let currentNotificationsCount = parseInt(
                            totalNotificationsCountElement.text()
                        );
                        currentNotificationsCount -= 1;
                        if (totalNotificationsCount == 0) {
                            totalNotificationsCountElement.text("");
                        } else {
                            totalNotificationsCountElement.text(
                                currentNotificationsCount
                            );
                        }
                        if (redirectUrl) {
                            location.href = redirectUrl;
                        }
                    },
                    error: function(jqXHR, textStatus, error) {
                        console.log(error);
                    }
                });
            });
        });
        var notif_id = [];
        $.each($("div.ui-notify.ui-notification-unread"), function(key, value) {
            notif_id.push($(value).attr("data-notify-id"));
        });
        if (notif_id.length > 0) {
            updateAllnotificationsAsRead(notif_id, true);
        }
    }
    let loadMoreNotificationsButton = $("#load-more-notifications-button");
    let notificationsListWrapper = $("#notification-list-wrapper");
    let newNotificationsFeedbackElement = $("#new-notifications-feedback");
    loadMoreNotificationsButton.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        let loadMoreNotificationsUrl = $(this).data(
            "load-more-notifications-url"
        );
        newNotificationsFeedbackElement.addClass("d-none").text("");
        let currentPage = $(this).data("current-page");
        currentPage = parseInt(currentPage) + 1;
        $.ajax({
            type: "GET",
            url: loadMoreNotificationsUrl + "?page=" + currentPage,
            dataType: "json",
            headers: { "X-CSRF-TOKEN": csrfToken },
            statusCode: {
                411: function () {
                    $('div.modal').removeClass('show');
                    window.location.href = '/profile';
                }
            },
            success: function(result) {
                if (!result.data.view_more) {
                    $("#load-more-notifications-button").hide();
                } else {
                    $("#load-more-notifications-button").show();
                }
                $("#load-more-notifications-button").data(
                    "current-page",
                    currentPage
                );
                let notificationsMarkups = result.data.notificationAsHtmlArray;
                notificationsMarkups.forEach(function(markup) {
                    notificationsListWrapper.append(markup);
                });
                let notificationListsElementList = $(
                    "[id^='list-notification-message-']"
                );

                attachNotificationUpdateStatusChangeHandler(
                    notificationListsElementList
                );
            },
            error: function(jqXHR, textStatus, error) {
                let response = jqXHR.responseText;
                if (response) {
                    response = JSON.parse(response);
                    let generalMessage = response.message;
                    newNotificationsFeedbackElement
                        .removeClass("d-none")
                        .text(generalMessage);
                } else {
                    newNotificationsFeedbackElement
                        .removeClass("d-none")
                        .text("Please check your network connection");
                }
            }
        });
    });

    //notifiactions update section
    let notificationReadAllUrl = $("#notifications-dropdown").data(
        "read-all-notifications-url"
    );

    let currentLocation = location.href;
    let locationParts = currentLocation.split("/");
    let lastSegment = locationParts[locationParts.length - 1];
    if (lastSegment == "profile#notify") {
        let notif_id = [];
        $.each($("div.ui-notify.ui-notification-unread"), function(key, value) {
            notif_id.push($(value).attr("data-notify-id"));
        });

        if (notif_id.length > 0) {
            updateAllnotificationsAsRead(notif_id, true);
        }
    }

    $("#myTab").on("hide.bs.tab", function(e) {
        if (e.target.id == "notify-tab") {
            let notif_id = [];
            $.each($("div.ui-notify.ui-notification-unread"), function(
                key,
                value
            ) {
                notif_id.push($(value).attr("data-notify-id"));
            });

            if (notif_id.length > 0) {
                updateAllnotificationsAsRead(notif_id, true);
            }
        }
    });

    $("#myTab").on("shown.bs.tab", function(e) {
        if (e.target.id == "notify-tab" && lastSegment != "profile#notify") {
            let notif_id = [];
            $.each($("div.ui-notify.ui-notification-unread"), function(
                key,
                value
            ) {
                notif_id.push($(value).attr("data-notify-id"));
            });

            if (notif_id.length > 0) {
                updateAllnotificationsAsRead(notif_id, true);
            }
        }
    });

    //mark all notifications as read
    function updateAllnotificationsAsRead(
        notif_id = [],
        isProfilePage = false
    ) {
        $.ajax({
            type: "GET",
            url: notificationReadAllUrl,
            dataType: "json",
            data: { notif_id },
            headers: { "X-CSRF-TOKEN": csrfToken },
            statusCode: {
                411: function () {
                    $('div.modal').removeClass('show');
                    window.location.href = '/profile';
                }
            },
            success: function(result) {
                if (result.data.unreadCount > 0) {
                    $("#total_notifications_count")
                        .attr(
                            "data-total-notifications",
                            result.data.unreadCount
                        )
                        .text(result.data.unreadCount);
                } else {
                    $("#total_notifications_count").addClass("hide");
                }

                var css_class = isProfilePage
                        ? "ui-notification-unread"
                        : "ui-unread",
                    icon_class = isProfilePage
                        ? ".ui-notify-icon img"
                        : ".ui-notification-icon";

                $.each($("." + css_class), function(key, value) {
                    var id = $(value).attr("data-id")
                        ? $(value).attr("data-id")
                        : $(value).attr("data-notify-id");
                    $(value).removeClass(css_class);
                    $(value)
                        .find(icon_class)
                        .attr("src", result.data.icons[id]);
                });
            }
        });
    }

    // mark notifications as read
    let notificationIcon = $("#notifications-dropdown");
    notificationIcon.on("hide.bs.dropdown", function(e) {
        let notificationReadAllUrl = $(this).data("read-all-notifications-url");
        let notif_id = [];
        $.each($("a.ui-notifications-link.ui-unread"), function(key, value) {
            notif_id.push($(value).attr("data-id"));
        });
        if (notif_id.length > 0) {
            updateAllnotificationsAsRead(notif_id);
        }
    });

    //clear all notifications
    let clearAllbtn = $("#notify_clear_all_btn");
    clearAllbtn.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        let clearAllUrl = $(this).data("clear-all-notify-url");

        $.ajax({
            type: "GET",
            url: clearAllUrl,
            dataType: "json",
            headers: { "X-CSRF-TOKEN": csrfToken },
            statusCode: {
                411: function () {
                    $('div.modal').removeClass('show');
                    window.location.href = '/profile';
                }
            },
            success: function(result) {
                $("#notify .card").html(
                    '<div class="ui-no-notification">' +
                        '<div class="d-flex  justify-content-center">' +
                        "<h5>No notifications</h5>" +
                        "</div>" +
                        '<div class="d-flex  justify-content-center">' +
                        "<p>There are no notification now we will let you know</p>" +
                        "</div>" +
                        "</div>"
                );
                location.href = "/profile#notify";
            },
            error: function(err) {
                console.log("Something went wrong when clearing notifications");
            }
        });
    });

    function fetchLatestNotifications() {
        var notification_count;
         $.ajax({
            type: "GET",
            url: $('#url').val() + '/notifications/list-latest',
            data: {last_refreshed_at: $('#last_refreshed_at').val()},
            headers: { "X-CSRF-TOKEN": csrfToken },
            success: function (result) {
                refreshed_at = $('<div>'+result+'</div>').find('.refreshed_at').val();
                const update_profile = $(`<div>${result}</div>`).find("[data-id='-1']").length===1;
                if(update_profile){
                    $("#notifications-dropdown ul [data-id='-1']").remove();
                    current_notifications_count = parseInt($.trim($('#total_notifications_count').html()));
                    $('#total_notifications_count').html(current_notifications_count - 1);
                }

                if (refreshed_at != '') {
                    $('#last_refreshed_at').val(refreshed_at);

                    notification_count = $(result).find('.ui-notifications-link').length;
                    $("#notifications-dropdown ul").prepend(result);

                    if ($('#total_notifications_count').length) {
                        current_notifications_count = parseInt($.trim($('#total_notifications_count').html()));
                        $('#total_notifications_count').attr('data-total-notifications', current_notifications_count + notification_count);
                        $('#total_notifications_count').html(current_notifications_count + notification_count);
                    } else {
                        $('#navbarDropdownMenuLink .ui-notification-icon').after('<span class="ui-count-tag" id="total_notifications_count" data-total-notifications="'+notification_count+'">'+notification_count+'</span>');
                    }
                }
            }
        });


    }
    setInterval(fetchLatestNotifications, 10000);

});
